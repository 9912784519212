import React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'

import styled from 'styled-components'
import { Container } from 'reactstrap'

import Icons from '../components/shared/Icons'

const HTML = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary);
    margin-bottom: 1em;
    margin-top: 1em;
  }
`
class Privacy extends React.Component {
  render() {
    return (
      <Layout>
        <StaticQuery
          query={graphql`
            {
              data: contentfulGlobal {
                policy: privacyPolicy {
                  id
                  g: childMarkdownRemark {
                    html
                  }
                }
              }
            }
          `}
          render={({ data }) => (
            <Container className="py-5">
              <HTML dangerouslySetInnerHTML={{ __html: data.policy.g.html }} />
            </Container>
          )}
        />
        <Icons />

      </Layout>
    )
  }
}

export default Privacy
